import React from 'react';

interface LogoMainProps {
  color?: string;
  width?: number;
}

const LogoMain: React.FC<LogoMainProps> = ({ color = '#1E1E1E', width = 300 }) => (
  <svg
    width={width}
    height={width / 5}
    viewBox="0 0 3000 600"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2960.6 341.088C2934.71 287.054 2880.68 263.415 2827.77 245.403C2794 235.272 2733.21 219.512 2733.21 175.61C2733.21 122.702 2816.51 127.204 2850.28 144.09C2861.54 149.719 2872.8 156.473 2884.05 166.604C2890.81 173.358 2903.19 173.358 2911.07 165.478L2958.35 119.325C2965.1 111.445 2965.1 100.188 2958.35 93.4333C2915.57 54.0337 2855.91 36.0225 2798.5 36.0225C2738.84 36.0225 2675.8 54.0337 2640.9 105.816C2615.01 144.09 2609.38 210.507 2631.89 252.158C2658.91 305.066 2710.69 324.203 2763.6 343.34C2799.62 354.597 2859.29 371.482 2859.29 417.636C2859.29 457.036 2815.38 467.167 2782.74 467.167C2737.71 467.167 2703.94 453.658 2672.42 422.139C2664.54 414.259 2652.16 414.259 2644.28 421.013L2597 468.293C2589.12 475.047 2589.12 486.304 2595.87 493.058C2648.78 545.966 2701.69 563.977 2775.98 563.977C2836.77 563.977 2885.18 550.469 2921.2 522.326C2972.98 481.801 2989.87 398.499 2960.6 341.088Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2533.96 538.086V61.9136C2535.08 51.7823 2526.08 43.9023 2515.95 43.9023H2439.4C2429.27 43.9023 2421.39 51.7823 2421.39 61.9136V355.722L2181.61 49.5309C2179.36 46.1538 2174.86 43.9023 2170.36 43.9023H2116.32C2106.19 43.9023 2097.19 51.7823 2097.19 61.9136V538.086C2097.19 548.218 2106.19 556.097 2116.32 556.097H2192.87C2203 556.097 2212.01 548.218 2212.01 538.086V248.78L2446.15 550.469C2449.53 553.846 2454.03 556.097 2458.54 556.097H2515.95C2526.08 556.097 2533.96 548.218 2533.96 538.086Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1975.61 342.214C1985.74 342.214 1993.62 333.208 1993.62 323.077V265.666C1993.62 255.535 1985.74 246.529 1975.61 246.529H1765.1V141.839H1997C2007.13 141.839 2016.14 132.833 2016.14 122.702V61.9136C2016.14 51.7823 2007.13 43.9023 1997 43.9023H1669.42C1659.29 43.9023 1651.41 51.7823 1651.41 61.9136V538.086C1651.41 548.218 1659.29 556.097 1669.42 556.097H2001.5C2011.63 556.097 2019.51 548.218 2019.51 538.086V476.173C2019.51 466.041 2010.51 457.036 2000.38 457.036H1765.1V342.214H1975.61Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1552.35 538.086V61.9136C1552.35 51.7823 1543.34 43.9023 1533.21 43.9023H1456.66C1446.53 43.9023 1438.65 51.7823 1438.65 61.9136V538.086C1438.65 548.218 1446.53 556.097 1456.66 556.097H1533.21C1543.34 556.097 1552.35 548.218 1552.35 538.086Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1374.48 206.004C1374.48 105.816 1299.06 43.9023 1201.13 43.9023H1006.38C996.248 43.9023 987.242 51.7823 987.242 61.9136V538.086C987.242 548.218 996.248 556.097 1006.38 556.097H1082.93C1093.06 556.097 1100.94 548.218 1100.94 538.086V139.587H1185.37C1229.27 139.587 1260.79 160.976 1260.79 206.004C1260.79 252.158 1229.27 276.923 1185.37 276.923H1179.74C1169.61 276.923 1160.6 285.929 1160.6 296.06V354.597C1160.6 364.728 1169.61 373.733 1179.74 373.733H1201.13C1299.06 373.733 1374.48 307.317 1374.48 206.004Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M923.077 531.332L737.336 55.1594C733.959 48.4052 727.204 43.9023 719.325 43.9023H641.651C633.771 43.9023 627.017 48.4052 624.765 55.1594L435.647 530.206C430.019 542.589 440.15 556.097 452.533 556.097H530.206C539.212 556.097 545.966 551.595 548.218 543.715L678.799 186.867L808.255 543.715C810.507 551.595 818.386 556.097 826.266 556.097H906.191C918.574 556.097 927.58 542.589 923.077 531.332Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M393.996 341.088C368.105 287.054 314.071 263.415 261.163 245.403C228.518 235.272 166.604 219.512 166.604 175.61C166.604 122.702 249.906 127.204 283.677 144.09C294.934 149.719 306.191 156.473 317.448 166.604C325.328 173.358 336.585 173.358 344.465 165.478L391.745 119.325C398.499 111.445 398.499 100.188 391.745 93.4333C348.968 54.0337 289.306 36.0225 231.895 36.0225C172.233 36.0225 110.319 54.0337 74.2964 105.816C49.5309 144.09 43.9024 210.507 65.2908 252.158C92.3077 305.066 144.09 324.203 196.998 343.34C233.021 354.597 292.683 371.482 292.683 417.636C292.683 457.036 248.78 467.167 217.261 467.167C171.107 467.167 138.462 453.658 105.816 422.139C97.9362 414.259 85.5534 414.259 77.6735 421.013L30.394 468.293C23.6397 475.047 22.514 486.304 30.394 493.058C83.302 545.966 135.084 563.977 209.381 563.977C271.295 563.977 318.574 550.469 354.597 522.326C406.379 481.801 423.265 398.499 393.996 341.088Z"
      fill={color}
    />
  </svg>
);

export default LogoMain;
