import React from 'react';
import ReactDOM from 'react-dom/client';

import { SapiensRouter } from 'presentation/routes';

import 'presentation/styles/styles.global.scss';

const root = ReactDOM.createRoot(window.document.getElementById('sapiens') as HTMLElement);

root.render(
  <React.StrictMode>
    <SapiensRouter />
  </React.StrictMode>,
);
