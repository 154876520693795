import React from 'react';
import { createBrowserRouter, RouterProvider, Link } from 'react-router-dom';

import { PagesHome } from '../../pages';

const SapiensRouter: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <PagesHome />,
      // TODO: <Pages404 />
      errorElement: (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h1
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0 10px',
            }}
          >
            <span>{'404'}</span>
            <span
              style={{
                fontWeight: 200,
                transform: 'scale(1.5)',
              }}
            >
              {' | '}
            </span>
            <span>{'Not Found'}</span>
          </h1>
          <br />
          <br />
          <Link to="/">{'Return Back'}</Link>
        </div>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default SapiensRouter;
