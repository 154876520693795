import React from 'react';
import { MdAlternateEmail } from 'react-icons/md';

// import { AzureAPI } from 'application/api';
import { LogoMain } from 'presentation/components/Logo';

import styles from './Home.module.scss';

const PagesHome: React.FC = () => {
  const test = async (): Promise<void> => {
    // eslint-disable-next-line
    console.log('test');

    // await AzureAPI.sendEmail({
    //   to: 'furcandev@gmail.com',
    //   name: '',
    // });
  };

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <LogoMain width={260} />
        <p className={styles.logoCaption}>{'software | consultancy'}</p>
      </div>

      <div className={styles.contact}>
        <p className={styles.contactEmail}>
          <span>{'tere'}</span>
          <MdAlternateEmail className={styles.contactEmailAt} />
          <span>{'sapienssoftware.ee'}</span>
        </p>

        <img
          onClick={test}
          className={styles.contactEesti}
          src="/assets/images/eesti.png"
          alt="Eesti Vabariik"
        />
      </div>
    </div>
  );
};

export default PagesHome;
